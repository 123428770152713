// Container .. main content section

/*------------------------------------------------------------------
[Table of contents]
1. main
	+ Main header / .main-header
	+ Main container / .main-container
	+ Footer / footer
------------------------------------------------------------------*/

main {
    display: block;
    width: 100%;
    height: 100%;
    // overflow-y: auto;
    float: left;
    .main-header {
        display: block;
        width: 100%;
        float: left;
        background-color: $main-content-header-bg;
        // border-bottom: 1px solid $sec-page-border-color;
        padding: 10px calc(#{$main-gtr} + 10px);
        @extend .z-depth-1;
        div.sec-page {
            display: block;
            float: left;
            width: 100%;
            height: $sec-page-height;
            background: $sec-page-bg;
            .page-title {
                display: inline-block;
                float: left;
                line-height: $sec-page-height;
                h2 {
                    font-size: $page-title-font;
                    color: $page-title-color;
                    @extend .no-mrpd;
                    line-height: $sec-page-height;
                }
            }
            .page-options {
                display: inline-block;
                float: right;
                height: $sec-page-height;
                line-height: $sec-page-height;
                a.page-opt-dropBtn {
                    display: block;
                    height: $sec-page-height;
                    line-height: $sec-page-height;
                    color: $page-page-opt-dropBtn-color;
                    float: left;
                    margin-left: 8px;
                    text-align: center;
                    i {
                        display: block;
                        margin: 0;
                        height: $sec-page-height;
                        width: $sec-page-height;
                        line-height: $sec-page-height;
                        font-size: $sidebar-font-size;
                        color: $morph-iconizedMenu-clr;
                    }
                }
                ul.action-ex-opts {
                    width: auto !important;
                    li {
                        a {
                            @extend .center-align;
                            padding: 0 15px !important;
                            i {
                                display: block;
                                width: 35px;
                                float: left;
                                height: $card-title-height;
                                line-height: $card-title-height;
                            }
                        }
                    }
                }
            }
        }
        div.sec-breadcrumb {
            display: block;
            width: 100%;
            float: left;
            background: $breadcrumb-bg-color;
            height: auto;
            min-height: $breadcrumb-height;
            line-height: $breadcrumb-height;
            nav.breadcrumbs-nav {
                display: block;
                float: left;
                width: calc(100% - 40px);
                height: auto;
                min-height: $breadcrumb-height;
                line-height: $breadcrumb-height;
                background: $breadcrumb-bg-color;
                box-shadow: none;
                .col {
                    a {
                        font-size: $breadcrumb-font-size;
                        color: $breadcrumb-txt-color;
                        font-weight: $breadcrumb-fontw;
                        &::before {
                            content: $breadcrumb-content;
                            color: $breadcrumb-content-color;
                            margin: 0;
                            font-size: $breadcrumb-content-font-size;
                            font-weight: $breadcrumb-content-fontw;
                        }
                        &:last-child {
                            color: $breadcrumb-activetxt-color;
                        }
                    }
                }
            }
        }
    }
    div.main-container {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: $main-gtr;
        float: left;
        min-height: calc(100vh - 218px);
        // overflow-y: auto;
        .row {
            display: block;
            clear: both;
            width: 100%;
            @extend .no-mrpd;
            &.spacer {
                margin-top: 10px !important;
            }
            .card {
                margin: $card-margin-top 0 $card-margin-bottom 0;
            }
            .card-panel {
                margin: $card-margin-top 0 $card-margin-bottom 0;
                @extend .z-depth-1;
                .row {
                    &.box-title {
                        margin-top: 0;
                        margin-bottom: 11.25px !important;
                        .col {
                            padding-top: 0;
                            h5 {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        h4 {
            &.content-headline {
                color: $primary-color;
                margin: $h4-content-headline-mt $h4-content-headline-mlr$h4-content-headline-mb $h4-content-headline-mlr !important;
            }
            &.content-sub-headline {
                color: $secondary-color;
                font-size: calc(#{$h4-fontsize} - 0.50rem);
            }
            +p {
                @extend .caption;
                margin-bottom: 20px !important;
            }
        }
        h5 {
            &.content-headline {
                color: $secondary-color;
                padding: 11.25px 0;
                margin-bottom:0 !important;
            }
            +p {
                margin: 10px 0 !important;
            }
        }
    }
    footer {
        &.page-footer {
            float: left;
            display: block;
            width: 100%;
        }
    }
    ul.action-ex-opts {
        min-width: 150px !important;
        li {
            display: block;
            float: left;
            height: $card-title-height;
            min-height: $card-title-height;
            line-height: $card-title-height;
            a {
                color: #424242 !important;
                height: $card-title-height;
                line-height: $card-title-height;
                @extend .no-mrpd;
                padding: 0 15px !important;
                width: 100%;
                span {
                    display: block;
                    float: left;
                    height: $card-title-height;
                    line-height: $card-title-height;
                    padding-left: 8px;
                }
                i {
                    display: block;
                    float: left;
                    height: $card-title-height;
                    line-height: $card-title-height;
                }
            }
        }
    }
    .waves-set {
        @include setWave();
    }
}