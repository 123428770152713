// NOTIFICATION SIDEBAR

/*------------------------------------------------------------------
[Table of contents]
1. Notification sidebar
2. Horizontal navigations (Default/iconized)
------------------------------------------------------------------*/

header {
    .notification-sidebar {
        position: fixed;
        right: -280px;
        top: 64px;
        height: calc(100% - 64px);
        width: 280px;
        margin: 0;
        padding: 0;
        z-index: 999;
        @include linear-gradient(to left, $left-sb-bg, $left-sb-bg-activeMenu);
        transition: all .3s ease-in-out;
        background: #212121;
        .col {
            padding: 0 !important;
            .rsb-notifications {
                li {
                    background-color: $notification-tab-bg;
                    color: $left-sb-text;
                    a {
                        font-size: 12px !important;
                        color: $notification-tab-text;
                        position: relative;
                        i {
                            line-height: 48px;
                            font-size: calc(#{$sidebar-font-size} + 4px);
                        }
                        span.badge-count {
                            position: absolute;
                            display: block;
                            @extend .no-mrpd;
                            border: 2px solid $notification-tab-bg;
                            background: $left-sb-text;
                            color: $notification-tab-bg;
                            font-size: 9px !important;
                            width: 22px;
                            height: 22px;
                            line-height: 20px;
                            @include border-radius(50%);
                            text-align: center;
                            font-weight: normal;
                            top: 5px;
                            right: calc(50% - 1.6rem);
                        }
                    }
                    &.tab {
                        a {
                            i {
                                color: #fff;
                            }
                        }
                    }
                }
                .indicator {
                    background-color: $head-nav-bg;
                }
            }
            .day-set {
                margin-top: 10px;
                overflow: hidden;
                p {
                    font-size: 24px;
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .collapsible {
            border: none;
            .collapsible-body {
                border: none;
                // background-color: $left-sb-icon-color;
            }
        }
        #today-notify {
            height: inherit;
            position: relative;
            .collapsible {
                overflow-y: auto;
                height: calc(100% - 38px);
            }
            .task-calender {
                #calendar {
                    box-shadow: none;
                    background-color: none;
                    .fc-toolbar {
                        margin: 0;
                        .fc-center,
                        .fc-left,
                        .fc-right {
                            h2 {
                                font-size: 18px;
                                padding: 0;
                                margin: 5px 0 0 0;
                                color: $cal-grey;
                            }
                            button {
                                line-height: 31.5px;
                                // background: #9e9e9e;
                            }
                        }
                    }
                    .fc-head {
                        border-color: $cal-dark-grey;
                        color: $cal-grey;
                        border-left: 0;
                        border-right: 0;
                        .fc-head-container {
                            border-color: $cal-dark-grey;
                            border-left: 0;
                            border-right: 0;
                        }
                        th {
                            border-left: 0;
                            border-right: 0;
                        }
                    }
                    .fc-body {
                        border-color: #fff;
                        border-left: 0;
                        border-right: 0;
                        .fc-widget-content {
                            border-left: 0;
                            border-right: 0;
                            border-color: $cal-dark-grey;
                            .fc-content-skeleton {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        #rsb-notifications {
            display: block;
            height: 100%;
            width: 100%;
            position: relative;
            background: #212121;
            ul.notification-list {
                width: 100%;
                height: calc(100vh - 150px);
                display: block;
                float: left;
                border: none;
                overflow-y: auto;
                padding: $nav-notification-list_pad;
                background: #212121;
                li {
                    &.waves-set {
                        @include setWave();
                    }
                    @extend .no-mrpd;
                    width: inherit;
                    border-bottom:none;
                    display: block;
                    float: left;
                    background: #212121;
                    &.notification-item {
                        // padding: 5px 5px 0 0 !important;
                        a {
                            display: block;
                            float: left;
                            @extend .no-mrpd;
                            &.notify-user {
                                padding: 0px 10px 0 8px !important;
                                line-height: 53px;
                                position: relative;
                                img {
                                    width: 40px;
                                    display: block;
                                }
                                span.notify-status {
                                    text-align: right;
                                    font-size: 12px;
                                    display: block;
                                    float: right;
                                    width: 10px;
                                    height: 10px;
                                    @include border-radius(50%);
                                    font-weight: 500;
                                    position: absolute;
                                    right: 5px;
                                    bottom: 20px;
                                    border: 2px solid $notify-status-brd;
                                    &.online {
                                        background: $notify-status-onlineClr;
                                    }
                                    &.offline {
                                        background: $notify-status-offlineClr;
                                    }
                                    &.away {
                                        background: $notify-status-awayClr;
                                    }
                                    &.busy {
                                        background: $notify-status-busyClr;
                                    }
                                }
                            }
                            &.notify-content {
                                width: calc( 100% - 65px);
                                &.full {
                                    width: 100%;
                                    padding-left: 8px;
                                }
                                span.title {
                                    width: 100%;
                                    display: block;
                                    float: left;
                                    color: $notify-usr-name-color;
                                    font-size: 14px;
                                    text-align: left;
                                    padding-left: 4px !important;
                                    span.badge-count {
                                        float: right;
                                        display: block;
                                        @extend .no-mrpd;
                                        border: 2px solid $notify-status-badgeBrd;
                                        background: $notify-status-badgeBg;
                                        color: $notify-status-badgeClr;
                                        font-size: 9px !important;
                                        width: 20px;
                                        height: 20px;
                                        line-height: 18px;
                                        @include border-radius(50%);
                                        text-align: center;
                                        font-weight: normal;
                                    }
                                    i.notify-type {
                                        @extend .tiny;
                                        float: right;
                                        display: block;
                                        @extend .no-mrpd;
                                        height: 22px;
                                        line-height: 22px;
                                    }
                                }
                                div.notify-desc {
                                    width: 100%;
                                    display: block;
                                    float: left;
                                    line-height: 1em;
                                    color: $notify-msgs-content-clr;
                                    text-align: left;
                                    line-height: $nav-msgs-contentline-height;
                                    font-size: 14px;
                                    font-weight: 300;
                                    padding-left: 4px !important;
                                    text-rendering: optimizeLegibility !important;
                                    -webkit-font-smoothing: antialiased !important;
                                }
                                span.notify-time {
                                    float: left;
                                    width: 100%;
                                    display: block;
                                    text-align: right;
                                    color: $notify-msgs-time-clr;
                                    font-size: 12px;
                                    line-height: 1.2em;
                                    padding: 5px 0 10px 0;
                                    text-rendering: optimizeLegibility !important;
                                    -webkit-font-smoothing: antialiased !important;
                                }
                            }
                            &:hover {
                                background: none;
                            }
                        }
                    }
                    &:hover {
                        // background: none;
                    }
                }
            }
            .footer {
                position: absolute;
                text-align: center;
                bottom: -15px;
                left: 0;
                background-color: $notification-tab-bg;
                height: 35px;
                line-height: 35px;
                width: 100%;
                z-index: 2;
                a {
                    color: $notification-tab-text;
                }
            }
        }
        #rsb-shortcuts {
            display: block;
            height: 100%;
            width: 100%;
            background: $notification-tab-bg;
            ul.tab-shortcut {
                width: 100%;
                height: calc(100vh - 110px);
                display: block;
                float: left;
                border: none;
                overflow-y: auto;
                // @extend .no-mrpd;
                margin: 0;
                padding: 8px 10px;
                li.collection-item {
                    &.waves-set {
                        @include setWave();
                    }
                    @extend .no-mrpd;
                    width: calc(50% - 10px);
                    border-bottom:none;
                    display: block;
                    float: left;
                    background: #424242;
                    margin: 5px 5px 5px 5px !important;
                    a.shortcut-aItem {
                        display: block;
                        @extend .no-mrpd;
                        float: left;
                        width: 100%;
                        height: 115px;
                        color: #fff;
                        position: relative;
                        span.shortcut-name {
                            display: block;
                            float: left;
                            width: 100%;
                            @extend .no-mrpd;
                            position: absolute;
                            top: 80px;
                            left: 0;
                            text-align: center;
                        }
                        i {
                            display: block;
                            float: left;
                            @extend .no-mrpd;
                            font-size: 3rem;
                            width: 100%;
                            position: absolute;
                            top: 30px;
                            left: calc(50% - 1.5rem);
                        }
                    }
                }
            }
        }
        #rsb-tasklist {
            display: block;
            height: 100%;
            width: 100%;
            background: $rsb-task-bg;
            position: relative;
            div.tab-notelist {
                width: 100%;
                height: calc(100vh - 148px);
                display: block;
                float: left;
                border: none;
                overflow-y: auto;
                // @extend .no-mrpd;
                margin: 0;
                padding: 8px 10px;
                min-height: 50px;
                p {
                    position: relative;
                    @extend .no-mrpd;
                    cursor: move;
                    height: auto;
                    line-height: 1em;
                    display: block;
                    float: left;
                    width: 100%;
                    padding: 5px 10px !important;
                    margin-bottom: 1px !important;
                    text-rendering: optimizeLegibility !important;
                    -webkit-font-smoothing: antialiased !important;
                    color: #FFF;
                    input {}
                    label {
                        text-rendering: optimizeLegibility !important;
                        -webkit-font-smoothing: antialiased !important;
                        color: #FFF;
                        width: 100%;
                    }
                    &.ui-sortable-helper {
                        @extend .z-depth-3;
                        background: $rsb-task-move-bg;
                    }
                }
            }
            div.notes-footer {
                position: absolute;
                text-align: center;
                bottom: -38px;
                left: 0;
                background-color: $notification-tab-bg;
                height: 35px;
                line-height: 35px;
                width: 100%;
                z-index: 2;
                a {
                    color: $notification-tab-text;
                    height: 38px;
                    width: 38px;
                    text-align: center;
                    // padding:0 5px;
                    &.waves-set {
                        @include setWave();
                    }
                }
            }
        }
    }
    .horizontal-navigations {
        position: relative;
        z-index: 99;
        nav {
            background: $horz-bg;
            ul {
                &.iconized {
                    li.menu-item {
                        float: left;
                        &.active {
                            a {
                                &.active {
                                    i {
                                        &.left {
                                            color: $left-sb-activeIcon-color !important;
                                        }
                                    }
                                }
                            }
                        }
                        a.menu-link {
                            height: $horz-menu-link;
                            position: relative;
                            float: left;
                            color: $horz-text-color;
                            font-size: $horz-items-font-size;
                            font-weight: $horz-collapsible-head-span-font-weight;
                            i.menu-icon {
                                font-size: $horz-menu-font-size;
                                color: $horz-sb-icon-color !important;
                                &.left {
                                    display: block;
                                    height: 26px;
                                    line-height: normal;
                                    margin-top: 10px;
                                    width: 100%;
                                    display: block;
                                    text-align: center;
                                    color: $horz-sb-icon-color;
                                }
                                &.right {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    color: $horz-right-icon-color;
                                }
                            }
                            span.menu-span {
                                display: block;
                                height: 28px;
                                line-height: normal;
                                width: 100%;
                                display: block;
                                text-align: center;
                                color: $horz-text-color;
                                font-size: $horz-items-font-size;
                                font-weight: $horz-collapsible-head-span-font-weight;
                            }
                            &:hover {
                                background: none;
                            }
                        }
                        &:hover {
                            background: none;
                        }
                        div.hor_sub {
                            position: absolute;
                            top: 64px;
                            background: $horz-item-bg;
                            z-index: 2;
                            &.full {
                                width: 100%;
                                left: 0;
                                padding-bottom: 10px;
                                height: auto;
                                .collection {
                                    width: $horz-hor_sub-collection_width;
                                    margin: 0;
                                    padding: 0 18px;
                                    border: none;
                                    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
                                    box-shadow: none;
                                    float: left;
                                    overflow: visible;
                                    li {
                                        a {
                                            font-size: $horz-items-font-size;
                                            font-weight: $horz-collapsible-head-span-font-weight;
                                        }
                                        &.collection-header {
                                            padding: 5px 20px;
                                            h6 {
                                                font-weight: 400;
                                                color: $horz-collection-header-color;
                                            }
                                        }
                                        &.collection-item {
                                            width: inherit;
                                            margin: 0;
                                            padding: 0;
                                            background: none;
                                            border: none;
                                            &:hover {
                                                background: $horz-collection-item-hover;
                                            }
                                            .dropdown-content {
                                                background: $horz-dropdown-content-bg;
                                                li {
                                                    height: 50px;
                                                    min-height: 50px;
                                                    background: $horz-dropdown-content-bg;
                                                    &:hover {
                                                        background: $horz-dropdown-content-hover-bg;
                                                    }
                                                    a {
                                                        height: 50px;
                                                        padding: 7px 16px;
                                                        color: $horz-text-color;
                                                        &:hover {
                                                            background: $horz-dropdown-content-hover-bg;
                                                        }
                                                        i {
                                                            font-size: $horz-menu-font-size;
                                                            color: $horz-sb-icon-color;
                                                        }
                                                    }
                                                }
                                                &:hover {
                                                    background: $horz-dropdown-content-hover-bg;
                                                }
                                            }
                                            a {
                                                width: inherit;
                                                margin: 0;
                                                height: $horz-item-height;
                                                line-height: $horz-item-height;
                                                color: $horz-text-color;
                                                &:hover {
                                                    background: $horz-collection-item-hover;
                                                }
                                                &.dropdown-button {
                                                    i {
                                                        font-size: $horz-menu-font-size;
                                                    }
                                                    &:hover {
                                                        background: none;
                                                    }
                                                }
                                                &.collapsible-header {
                                                    background: none;
                                                    width: inherit;
                                                    margin: 0;
                                                    height: $horz-item-height;
                                                    min-height: $horz-item-height;
                                                    line-height: $horz-item-height;
                                                    border: none;
                                                }
                                                i {
                                                    height: $horz-item-height;
                                                    line-height: $horz-item-height;
                                                    color: $horz-sb-icon-color;
                                                    font-size: $horz-menu-font-size;
                                                    &.active {
                                                        color: $horz-sb-activeIcon-color;
                                                    }
                                                }
                                            }
                                            .collapsible-body {
                                                border: none;
                                                width: inherit;
                                                overflow: hidden;
                                                ul {
                                                    width: inherit;
                                                    border: none;
                                                    li {
                                                        width: inherit;
                                                        border: none;
                                                        float: left;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.standard {
                                position: absolute;
                                width: 260px;
                                ul.collection {
                                    width: 260px;
                                    margin: 0;
                                    border: none;
                                    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
                                    box-shadow: none;
                                    li {
                                        &.collection-item {
                                            width: inherit;
                                            margin: 0;
                                            padding: 0;
                                            background: none;
                                            border: none;
                                            &:hover {
                                                background: none;
                                            }
                                            a {
                                                width: inherit;
                                                margin: 0;
                                                padding: $horz-icon-tb-pad $horz-icon-lr-pad;
                                                height: $horz-item-height;
                                                line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                color: $horz-text-color;
                                                font-size: $horz-items-font-size;
                                                font-weight: $horz-collapsible-head-span-font-weight;
                                                &:hover {
                                                    background: none;
                                                }
                                                &.collapsible-header {
                                                    background: none;
                                                    width: inherit;
                                                    margin: 0;
                                                    padding: $horz-icon-tb-pad 8px;
                                                    height: $horz-item-height;
                                                    min-height: $horz-item-height;
                                                    line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    border: none;
                                                    &.active {
                                                        i {
                                                            color: $left-sb-activeIcon-color;
                                                        }
                                                    }
                                                }
                                                i {
                                                    line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    color: $horz-sb-icon-color;
                                                    font-size: $horz-menu-font-size;
                                                }
                                            }
                                        }
                                        &.collection-header {
                                            height: $horz-item-height;
                                            line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                            padding: $horz-icon-tb-pad $horz-icon-lr-pad;
                                            h6 {
                                                font-weight: 400;
                                            }
                                        }
                                        .collapsible-body {
                                            border: none;
                                            width: inherit;
                                            overflow: hidden;
                                            ul {
                                                width: inherit;
                                                border: none;
                                                li {
                                                    width: inherit;
                                                    border: none;
                                                    float: left;
                                                    // background: $left-sb-bg-activeMenu;
                                                    a {
                                                        height: $horz-item-height;
                                                        line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                        padding: $horz-icon-tb-pad 10px $horz-icon-tb-pad 30px;
                                                        font-size: $horz-items-font-size;
                                                        font-weight: $horz-collapsible-head-span-font-weight;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.default {
                    li.menu-item {
                        float: left;
                        &.active {
                            a {
                                &.active {
                                    i {
                                        &.left {
                                            color: $left-sb-activeIcon-color !important;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            background: none;
                        }
                        a.menu-link {
                            color: $horz-text-color;
                            font-size: $horz-items-font-size;
                            font-weight: $horz-collapsible-head-span-font-weight;
                            i.menu-icon {
                                color: $horz-sb-icon-color;
                                font-size: $horz-menu-font-size;
                                &.left {}
                                &.right {}
                            }
                            span.menu-span {}
                            &:hover {
                                background: none;
                            }
                        }
                        .hor_sub {
                            position: absolute;
                            top: 64px;
                            background: $horz-item-bg;
                            z-index: 2;
                            &.full {
                                width: 100%;
                                left: 0;
                                padding-bottom: 10px;
                                height: auto;
                                .collection {
                                    width: $horz-hor_sub-collection_width;
                                    margin: 0;
                                    padding: 0 18px;
                                    border: none;
                                    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
                                    box-shadow: none;
                                    float: left;
                                    overflow: visible;
                                    li {
                                        &.collection-header {
                                            padding: 5px 20px;
                                            h6 {
                                                font-weight: 400;
                                                color: $horz-collection-header-color;
                                            }
                                        }
                                        &.collection-item {
                                            width: inherit;
                                            margin: 0;
                                            padding: 0;
                                            background: none;
                                            border: none;
                                            &:hover {
                                                background: $horz-collection-item-hover;
                                            }
                                            .dropdown-content {
                                                background: $horz-dropdown-content-bg;
                                                li {
                                                    height: 50px;
                                                    min-height: 50px;
                                                    background: $horz-dropdown-content-bg;
                                                    &:hover {
                                                        background: $horz-dropdown-content-hover-bg;
                                                    }
                                                    a {
                                                        height: 50px;
                                                        padding: 7px 16px;
                                                        color: $horz-text-color;
                                                        font-size: $horz-items-font-size;
                                                        font-weight: $horz-collapsible-head-span-font-weight;
                                                        &:hover {
                                                            background: $horz-dropdown-content-hover-bg;
                                                        }
                                                        i {
                                                            font-size: $horz-menu-font-size;
                                                            color: $horz-sb-icon-color;
                                                        }
                                                    }
                                                }
                                                &:hover {
                                                    background: $horz-dropdown-content-hover-bg;
                                                }
                                            }
                                            a {
                                                width: inherit;
                                                margin: 0;
                                                height: $horz-item-height;
                                                line-height: $horz-item-height;
                                                color: $horz-text-color;
                                                font-size: $horz-items-font-size;
                                                font-weight: $horz-collapsible-head-span-font-weight;
                                                &:hover {
                                                    background: $horz-collection-item-hover;
                                                }
                                                &.dropdown-button {
                                                    i {
                                                        font-size: $horz-menu-font-size;
                                                    }
                                                    &:hover {
                                                        background: none;
                                                    }
                                                }
                                                &.collapsible-header {
                                                    background: none;
                                                    width: inherit;
                                                    margin: 0;
                                                    height: $horz-item-height;
                                                    min-height: $horz-item-height;
                                                    line-height: $horz-item-height;
                                                    border: none;
                                                }
                                                i {
                                                    height: $horz-item-height;
                                                    line-height: $horz-item-height;
                                                    color: $horz-sb-icon-color;
                                                    font-size: $horz-menu-font-size;
                                                    &.active {
                                                        color: $horz-sb-activeIcon-color;
                                                    }
                                                }
                                            }
                                            .collapsible-body {
                                                border: none;
                                                width: inherit;
                                                overflow: hidden;
                                                ul {
                                                    width: inherit;
                                                    border: none;
                                                    li {
                                                        width: inherit;
                                                        border: none;
                                                        float: left;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.standard {
                                position: absolute;
                                width: 260px;
                                ul.collection {
                                    width: 260px;
                                    margin: 0;
                                    border: none;
                                    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
                                    box-shadow: none;
                                    li {
                                        &.collection-item {
                                            width: inherit;
                                            margin: 0;
                                            padding: 0;
                                            background: none;
                                            border: none;
                                            &:hover {
                                                background: none;
                                            }
                                            a {
                                                width: inherit;
                                                margin: 0;
                                                padding: $horz-icon-tb-pad $horz-icon-lr-pad;
                                                height: $horz-item-height;
                                                line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                color: $horz-text-color;
                                                font-size: $horz-items-font-size;
                                                font-weight: $horz-collapsible-head-span-font-weight;
                                                &:hover {
                                                    background: none;
                                                }
                                                &.collapsible-header {
                                                    background: none;
                                                    width: inherit;
                                                    margin: 0;
                                                    padding: $horz-icon-tb-pad 8px;
                                                    height: $horz-item-height;
                                                    min-height: $horz-item-height;
                                                    line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    border: none;
                                                    &.active {
                                                        i {
                                                            color: $left-sb-activeIcon-color;
                                                        }
                                                    }
                                                }
                                                i {
                                                    line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                    color: $horz-sb-icon-color;
                                                    font-size: $horz-menu-font-size;
                                                }
                                            }
                                        }
                                        &.collection-header {
                                            height: $horz-item-height;
                                            line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                            padding: $horz-icon-tb-pad $horz-icon-lr-pad;
                                            h6 {
                                                font-weight: 400;
                                            }
                                        }
                                        .collapsible-body {
                                            border: none;
                                            width: inherit;
                                            overflow: hidden;
                                            ul {
                                                width: inherit;
                                                border: none;
                                                li {
                                                    width: inherit;
                                                    border: none;
                                                    float: left;
                                                    // background: $left-sb-bg-activeMenu;
                                                    a {
                                                        height: $horz-item-height;
                                                        line-height: calc(#{$horz-item-height} - #{$horz-icon-tb-pad*2});
                                                        padding: $horz-icon-tb-pad 10px $horz-icon-tb-pad 30px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .waves-set {
            @include setWave();
        }
    }
}

.open-right-panel {
    right: 0px !important;
}


/*NOTIFICATION SIDEBAR ENDS*/