// Preloader Overlays ... Main full overlay
$color_mine_shaft_approx: #FFF;
$color_log_cabin_approx: #FFF;
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 auto;
    z-index: $preloader-overlay-z;
    background-color: $body-color;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    animation-duration: 1.5s;
    animation-delay: 0.1;
    animation-duration: 20000000s;
    // animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
    .preloader-center {
        @extend .animated;
        z-index: calc(#{$preloader-overlay-z} + 1);
        &.loaded {
            @extend .fadeOut;
            opacity: 0;
            z-index: -1;
        }
    }
    &.loaded {
        .cube-preload {
            width: 100%;
            height: 100%;
            .preload-cube {
                width: 33.33%;
                height: 33.33%;
                background-color: $color_mine_shaft_approx;
                display: block;
                float: left;
                animation: preload-cubeGridScaleDelay 1.3s ease-in-out;
                &.preload-cube1 {
                    animation-delay: 0.2s;
                }
                &.preload-cube2 {
                    animation-delay: 0.3s;
                }
                &.preload-cube3 {
                    animation-delay: 0.4s;
                }
                &.preload-cube4 {
                    animation-delay: 0.1s;
                }
                &.preload-cube5 {
                    animation-delay: 0.2s;
                }
                &.preload-cube6 {
                    animation-delay: 0.3s;
                }
                &.preload-cube7 {
                    animation-delay: 0s;
                }
                &.preload-cube8 {
                    animation-delay: 0.1s;
                }
                &.preload-cube9 {
                    animation-delay: 0.2s;
                }
            }
        }
        opacity: 0;
        z-index: -1;
    }
}

@-webkit-keyframes preload-cubeGridScaleDelay {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    70% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    100% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    35% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    70% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    100% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(1, 1, 1);
    }
    35% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3D(0, 0, 1);
    }
}