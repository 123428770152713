// TOP SEARCH
$search-txt-color:#FFF;
// SIDENAV
$sidenav-color:#212121;
$sidenav-color-light:#484848;
$sidenav-color-dark:#000000;
$left-sb-bg-activeMenu: #212121; // left sidebat active menu bg
$left-sb-hover:#424242;
$left-sb-activeIcon-color:#FFF; // Left active sidebar icon color
$left-sb-icon-color:#bdbdbd; // Left sidebar icon color
$sidenav-font-color: rgba(224, 224, 224, .87); // rgba(0,0,0,.87)
$left-sb-nested-bg:#070707;
$left-navigation-header-text:#bdbdbd;
// Horizotal Nav
$horz-collection-item-hover:#424242;
$horz-dropdown-content-bg:#212121;
$horz-dropdown-content-hover-bg:#212121;
$horz-sb-icon-color:#bdbdbd; // Left sidebar icon color
$horz-text-color:#FFF;
$horz-collection-header-color:none;
$horz-item-bg:#424242;
$horz-right-icon-color:#FFFFFF;