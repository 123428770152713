/* TOP NAV */

.full-top-nav {
    nav {
        @include topNav($head-nav-height, $head-nav-bg, $head-nav-textColor);
        left: 0;
        a.button-collapse.top-nav {
            width: 4em;
            left: 0;
        }
    }
    .nav-wrapper {
        position: relative;
        .full-top-nav-a {
            i {
                line-height: inherit !important;
            }
        }
        form#app-search {
            background: $head-nav-bg;
            position: absolute;
            height: $head-nav-height;
            width: 100%;
            z-index: 3;
            &.active {
                display: block;
                opacity: 1;
            }
            &.inactive {
                display: none;
                opacity: 0;
            }
            input#search {
                color: $search-txt-color;
                background: $left-sb-bg;
                height: inherit;
            }
            i.material-icons {
                color: $secondary-color;
                line-height: $head-nav-height;
                height: $head-nav-height;
            }
        }
        .topnav-hidden-menu {
            position: absolute;
            top: 0;
            height: $head-nav-height;
            width: 100%;
            z-index: 3;
            background: #000;
        }
        .nav-logo {
            left: 160px !important;
            margin-left: 0px !important;
        }
        // Logo Code area
        .defaultMenu-logo,
        .nav-logo {
            position: absolute;
            @extend .app-logo;
        }
        .iconizedMenu-act {
            width: $iconized-menu-width;
            transition: width 0.5s;
            transition-timing: linear;
        }
        .topnav-Menu-ls {
            position: absolute;
        }
        .default-active {
            left: $default-left-mr;
        }
        .iconized-active {
            left: $iconized-left-mr;
        }
        ul {
            &.right {
                display: block;
                .list-common {
                    display: block;
                    float: left;
                    height: $head-nav-height;
                    line-height: $head-nav-height;
                }
                li {
                    @extend .list-common;
                    a {
                        &.dropdown-button {
                            position: relative;
                            @extend .list-common;
                            i {
                                @extend .list-common;
                            }
                            img {
                                @extend .list-common;
                                padding: 10px 0px;
                            }
                            span.badge-count {
                                position: absolute;
                                display: block;
                                @extend .no-mrpd;
                                border: 2px solid $chat-status-badgeBrd;
                                background: $chat-status-badgeBg;
                                color: $chat-status-badgeClr;
                                font-size: 9px !important;
                                width: 20px;
                                height: 20px;
                                line-height: 18px;
                                @include border-radius(50%);
                                text-align: center;
                                font-weight: normal;
                                top: 10px;
                                right: 10px;
                            }
                        }
                    }
                }
            }
        }
        #top-nav-notifications {}
        #top-nav-msgs,
        #top-nav-msgsweb {
            width: $nav-msgs-w !important;
            float: left;
            border: none;
            li.collection-item {
                &:hover {
                    background: none;
                }
                @extend .no-mrpd;
                border: none;
                box-shadow:none;
                width: inherit;
                &.msg-header {
                    width: 100%;
                    height: 40px;
                    max-height: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    padding: 0 10px !important;
                    border-bottom: 1px solid $nav-msg-head-foot-brd;
                    overflow: hidden;
                    ul {
                        display: block;
                        float: left;
                        @extend .no-mrpd;
                        width: inherit;
                        height: inherit;
                        li {
                            display: block;
                            @extend .no-mrpd;
                            width: inherit;
                            height: inherit;
                            &:hover {
                                background: none;
                            }
                            span {
                                display: block;
                                float: left;
                                height: 40px;
                                line-height: 40px;
                                @extend .no-mrpd;
                                padding-left: 10px;
                                color: $nav-msg-head-color;
                            }
                            a {
                                display: block;
                                padding: 0 10px;
                                @extend .no-mrpd;
                                color: $nav-msg-head-color;
                                span {
                                    height: 40px;
                                    line-height: 40px;
                                    @extend .no-mrpd;
                                }
                                i {
                                    height: 40px;
                                    line-height: 40px;
                                    @extend .no-mrpd;
                                    font-size: 18px;
                                }
                                &:hover {
                                    background: none;
                                }
                            }
                        }
                    }
                }
                &.msg-content {
                    display: block;
                    float: left;
                    width: 100%;
                    height: $nav-msgs-mh;
                    max-height: $nav-msgs-mh;
                    min-height: $nav-msgs-mh;
                    padding-top: 10px !important;
                    // overflow-y: auto;
                    line-height: initial;
                    ul.collection {
                        max-height: $nav-msgs-mh;
                        overflow-y: auto;
                        float: left;
                        box-shadow: none;
                        border: none;
                        @extend .no-mrpd;
                        li {
                            @extend .no-mrpd;
                            width: inherit;
                            min-height: 64px;
                            height: auto;
                            box-shadow: none;
                            border: none;
                            &.msg-items {
                                padding: 5px 5px 0 0;
                                a {
                                    display: block;
                                    float: left;
                                    height: 100%;
                                    @extend .no-mrpd;
                                    &.chat-user {
                                        padding: 0px 10px !important;
                                        line-height: 53px;
                                        position: relative;
                                        img {
                                            width: 40px;
                                            display: block;
                                        }
                                        span.chat-status {
                                            text-align: right;
                                            font-size: 12px;
                                            display: block;
                                            float: right;
                                            width: 10px;
                                            height: 10px;
                                            @include border-radius(50%);
                                            font-weight: 500;
                                            position: absolute;
                                            right: 5px;
                                            bottom: 20px;
                                            border: 2px solid $chat-status-brd;
                                            &.with-badge {
                                                right: 0;
                                                top: 0;
                                                height: 20px;
                                                width: 20px;
                                                line-height: 18px;
                                                text-align: center;
                                                font-size: 10px;
                                                color: #fff;
                                            }
                                            &.online {
                                                background: $chat-status-onlineClr;
                                            }
                                            &.offline {
                                                background: $chat-status-offlineClr;
                                            }
                                            &.away {
                                                background: $chat-status-awayClr;
                                            }
                                            &.busy {
                                                background: $chat-status-busyClr;
                                            }
                                        }
                                    }
                                    &.chat-content {
                                        width: calc( 100% - 60px);
                                        padding-right: 8px !important;
                                        span.title {
                                            width: 100%;
                                            display: block;
                                            float: left;
                                            color: $chat-usr-name-color;
                                            font-size: 14px;
                                            text-align: left;
                                            padding-left: 5px;
                                            span.badge-count {
                                                float: right;
                                                display: block;
                                                @extend .no-mrpd;
                                                border: 2px solid $chat-status-badgeBrd;
                                                background: $chat-status-badgeBg;
                                                color: $chat-status-badgeClr;
                                                font-size: 9px !important;
                                                width: 20px;
                                                height: 20px;
                                                line-height: 18px;
                                                @include border-radius(50%);
                                                text-align: center;
                                                font-weight: normal;
                                            }
                                        }
                                        div.notify-desc {
                                            width: 100%;
                                            display: block;
                                            float: left;
                                            line-height: 1.2rem;
                                            color: $nav-msgs-content-clr-msg;
                                            text-align: left;
                                            font-size: 14px;
                                            padding-left: 5px;
                                            text-rendering: optimizeLegibility !important;
                                            -webkit-font-smoothing: antialiased !important;
                                        }
                                        span.chat-time {
                                            float: left;
                                            width: 100%;
                                            display: block;
                                            text-align: right;
                                            color: $nav-msgs-time-clr;
                                            font-size: 12px;
                                            line-height: 1.3em;
                                            text-rendering: optimizeLegibility !important;
                                            -webkit-font-smoothing: antialiased !important;
                                        }
                                    }
                                    &:hover {
                                        background: none;
                                    }
                                }
                            }
                            &:hover {
                                background: none;
                            }
                        }
                    }
                }
                &.msg-footer {
                    width: 100%;
                    height: 35px;
                    max-height: 35px;
                    min-height: 35px;
                    line-height: 35px;
                    border-top: 1px solid $nav-msg-head-foot-brd;
                    overflow: hidden;
                    z-index: 2;
                    margin-top: 10px !important;
                    .clear-style {
                        float: none !important;
                        width: auto !important;
                        margin: 0;
                    }
                    ul {
                        display: block;
                        float: left;
                        @extend .no-mrpd;
                        width: inherit;
                        li {
                            display: block;
                            float: left;
                            @extend .no-mrpd;
                            width: inherit;
                            &:hover {
                                background: none;
                            }
                            span {
                                display: block;
                                float: left;
                                height: 35px;
                                line-height: 35px;
                                @extend .no-mrpd;
                                padding-left: 10px;
                                color: $nav-msg-head-color;
                            }
                            a {
                                display: block;
                                padding: 0 10px;
                                @extend .no-mrpd;
                                color: $nav-msg-head-color;
                                span {
                                    height: 35px;
                                    line-height: 35px;
                                    @extend .no-mrpd;
                                }
                                i {
                                    height: 35px;
                                    line-height: 35px;
                                    @extend .no-mrpd;
                                }
                                &:hover {
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        #top-nav-userProfile {
            // display: block;
            width: $nav-rt-profile-width !important;
            height: $nav-rt-profile-height;
            overflow-y: hidden;
            li {
                @extend .no-mrpd;
                width: inherit;
                .admin-profile-content {
                    width: inherit;
                    padding: 10px 0;
                    img.user-profile-img {
                        width: 75px;
                        margin: 5px auto;
                        display: block;
                    }
                    p {
                        @extend .no-mrpd;
                        &.user-name {
                            width: inherit;
                            text-align: center;
                            font-weight: bold;
                            line-height: 1.5em;
                        }
                        &.user-designation {
                            width: inherit;
                            text-align: center;
                            line-height: 1.5em;
                            padding-bottom: 10px !important;
                        }
                    }
                    ul.profile-ul {
                        @extend .no-mrpd;
                        display: block;
                        ;
                        float: left;
                        width: 100%;
                        li.profile-li {
                            @extend .no-mrpd;
                            display: block;
                            width: 100%;
                            height: $nav-rt-profile-menu-height;
                            min-height: $nav-rt-profile-menu-height;
                            line-height: $nav-rt-profile-menu-height;
                            float: left;
                            padding: 0 15px 0 10px !important;
                            a {
                                @extend .no-mrpd;
                                width: 100%;
                                display: block;
                                float: left;
                                height: $nav-rt-profile-menu-height;
                                line-height: $nav-rt-profile-menu-height;
                                box-shadow: none;
                                color: $nav-rt-profile-menu-color;
                                background: $nav-rt-profile-menu-bg;
                                i {
                                    display: block;
                                    @extend .no-mrpd;
                                    width: 30px;
                                    font-size: 1rem;
                                    height: $nav-rt-profile-menu-height;
                                    line-height: $nav-rt-profile-menu-height;
                                }
                                span {
                                    @extend .no-mrpd;
                                    display: block;
                                    width: calc(100% - 45px);
                                    height: $nav-rt-profile-menu-height;
                                    line-height: $nav-rt-profile-menu-height;
                                    text-align: left;
                                    text-transform: initial;
                                    &.badge {
                                        font-weight: 300;
                                        font-size: 0.8rem;
                                        border-radius: 2px;
                                        width: 3rem;
                                        min-width: 3rem;
                                        max-width: 3rem;
                                        padding: 0 6px;
                                        height: 20px;
                                        margin-left: 14px;
                                        text-align: center;
                                        line-height: 20px;
                                        margin: 8px 0 !important;
                                        color: #fff;
                                        float: right;
                                        box-sizing: border-box;
                                    }
                                }
                                &:hover {
                                    color: $nav-rt-profile-menuColor-hover;
                                    background: $nav-rt-profile-menuBg-hover;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    background: none;
                }
            }
        }
    }
}

#sidebar-iconized-collapse,
#sidebar-default-collapse {
    height: auto;
    margin: 15px 10px;
    background-color: none;
    span {
        span {
            background-color: $morph-toggleMenu-clr;
        }
    }
}

.notification-toggle-open {
    height: auto;
    margin: 5px 10px;
    background-color: none;
    padding: 0;
    span {
        span {
            background-color: $morph-notification-clr
        }
    }
}

.iconizedToggle {
    height: auto;
    margin: 5px 10px;
    @extend .transparent;
    padding: 0;
    span {
        span {
            background-color: $morph-iconizedMenu-clr;
        }
    }
}


/* TOP-NAV ENDS */

.app-logo {
    height: inherit;
    // width: $left-sb-width;
    background-color: $defaultMenu-logo-bg;
    padding: 10px 5px;
    transition: width 1s;
    transition-delay: 0.05s;
    transition-timing: linear;
    left: 0;
    font-size: $logo-font-size;
    font-family: "anuratiregular";
    margin-left: 20px;
    img {
        height: inherit;
        float: left;
        // padding-left: 30px;
    }
    span {
        display: block;
        // width: calc(100% - 85px);
        float: left;
        height: inherit;
        line-height: 48px;
        font-size: $logo-font-size;
        font-family: "anuratiregular";
        color: $logo-text-color;
    }
    .logo-icon {
        position: absolute;
        top: 0;
        font-size: calc(#{$logo-font-size} + 6px);
        line-height: $head-nav-height;
        height: $head-nav-height;
        left: -10px;
        /* &.iconized{
			left: -10px;
			font-size: calc(#{$logo-font-size} + 6px);
			top:0;
		} */
    }
    .app-icon {
        position: absolute;
        top: -16px;
        font-size: calc(#{$logo-font-size} + 6px);
        left: 20px;
        line-height: $head-nav-height;
        height: $head-nav-height;
        &.iconized {
            left: -10px;
            font-size: calc(#{$logo-font-size} + 6px);
            top: 0;
        }
    }
}