// THEME SeTTING
.themeSetting {
    position: fixed;
    right: 0;
    top: 64px;
    width: 0;
    height: 305px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    z-index: 9999;
    .toggleThemesetting {
        position: absolute;
        left: -36px;
        width: 36px;
        padding: 0;
        top: calc(50% - 30px);
        i {
            color: $secondary-color;
        }
        background: #FFF;
    }
    .settings {
        color: white;
        width: inherit;
        background: #FFF;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        float: left;
        h2 {
            font-size: 16px;
            margin: 0;
            width: 100%;
            padding: 5px 10px;
            background: #333;
        }
    }
    p.navopt {
        margin: 0;
        padding: 15px 15.5px 7px 15.5px;
        &:last-child {
            padding-left: 0;
            text-align: right;
        }
        label {
            height: inherit;
            &::before,
            &::after {
                top: auto !important;
                right: -1px !important;
                left: auto !important;
                bottom: 10px !important;
            }
        }
    }
    .navigationSetting {
        .divider {
            margin: 3px auto;
        }
    }
}

.themeSetting.active {
    width: $rsb-width;
}

img.theme-svg {
    width: $theme-svg-w;
}

label.theme-setting-sv-label {
    padding-left: 0 !important;
}

.themeSetting.inactive {
    width: 0px;
}