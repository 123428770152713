/**
 * Lock Screen
 */

.page-lock {
    position: fixed;
    // background: $lock-bg;
    &.norepeat-bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &::before {
            @extend .overlay1;
        }
    }
    &::before {
        z-index: 0;
    }
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin: 0 !important;
    padding: 0;
    .page-lock-wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: $lock-height;
        width: inherit;
        .row {
            @extend .no-mrpd;
            .lock-logo {
                @extend .center;
                height: $lock-logo-section-height;
                margin: 0;
                background: $primary-color;
                .lock-logo-inner {
                    /* background: rgba(38, 166, 154, 0.9);
					height: 50px;
					width: 50px; */
                    height: $lock-logo-section-height;
                    // margin: 0 auto;
                    img {
                        height: 40px;
                        float: left;
                        margin: 6px 0;
                    }
                    h2 {
                        float: left;
                        font-size: 24px;
                        margin: 0;
                        padding: 0 0 0 10px;
                        line-height: $lock-logo-section-height;
                        height: inherit;
                    }
                }
            }
            &.inner-content {
                background: rgba(255, 255, 255, 0.1);
                .lock-pic {
                    height: calc(#{$lock-height} - #{$lock-logo-section-height});
                    margin: 0;
                    padding: 0;
                    img {
                        @extend .responsive-img;
                        width: 100%;
                        height: inherit;
                    }
                }
                .lock-content {
                    height: calc(#{$lock-height} - #{$lock-logo-section-height});
                    margin: 0;
                    padding: 15px;
                    h2 {
                        &.user-name {
                            font-size: 22px;
                            margin: 0;
                        }
                    }
                    a {
                        &.user-email {
                            font-size: 12px;
                        }
                        &.not-user {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    .content-form {
                        .row {
                            @extend .no-mrpd,
                            .center;
                            .col {
                                @extend .no-mrpd;
                                input {
                                    @extend .no-mrpd;
                                }
                            }
                        }
                    }
                    .lock-screen-options {}
                }
            }
        }
    }
} // Lock Screen Ends