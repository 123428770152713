$color-one: $warning-color;
$color-two: $info-color;
$color-three: $success-color;
$color-four: $error-color;
.dots-loader:not(:required) {
    opacity: 1;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
    text-indent: -9999px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: transparent;
    // border-radius: 100%;
    box-shadow: $color-one -15px -15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    animation: dots-loader 5s infinite ease-in-out;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: .3s all;
    z-index: calc(#{$preloader-overlay-z} + 1);
    &.dot-circle {
        border-radius: 100%;
    }
}

@keyframes dots-loader {
    0% {
        box-shadow: $color-one -15px -15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
    8.33% {
        box-shadow: $color-one 15px -15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
    16.67% {
        box-shadow: $color-one 15px 15px 0 7px, $color-two 15px 15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
    25% {
        box-shadow: $color-one -15px 15px 0 7px, $color-two -15px 15px 0 7px, $color-three -15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
    33.33% {
        box-shadow: $color-one -15px -15px 0 7px, $color-two -15px 15px 0 7px, $color-three -15px -15px 0 7px, $color-four -15px -15px 0 7px;
    }
    41.67% {
        box-shadow: $color-one 15px -15px 0 7px, $color-two -15px 15px 0 7px, $color-three -15px -15px 0 7px, $color-four 15px -15px 0 7px;
    }
    50% {
        box-shadow: $color-one 15px 15px 0 7px, $color-two -15px 15px 0 7px, $color-three -15px -15px 0 7px, $color-four 15px -15px 0 7px;
    }
    58.33% {
        box-shadow: $color-one -15px 15px 0 7px, $color-two -15px 15px 0 7px, $color-three -15px -15px 0 7px, $color-four 15px -15px 0 7px;
    }
    66.67% {
        box-shadow: $color-one -15px -15px 0 7px, $color-two -15px -15px 0 7px, $color-three -15px -15px 0 7px, $color-four 15px -15px 0 7px;
    }
    75% {
        box-shadow: $color-one 15px -15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px -15px 0 7px, $color-four 15px -15px 0 7px;
    }
    83.33% {
        box-shadow: $color-one 15px 15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four 15px 15px 0 7px;
    }
    91.67% {
        box-shadow: $color-one -15px 15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
    100% {
        box-shadow: $color-one -15px -15px 0 7px, $color-two 15px -15px 0 7px, $color-three 15px 15px 0 7px, $color-four -15px 15px 0 7px;
    }
}